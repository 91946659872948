import { Component, OnInit } from "@angular/core";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "flavors",
  templateUrl: "./flavors.component.html",
  styleUrls: ["./flavors.component.css"],
})
export class FlavorsComponent implements OnInit {
  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
