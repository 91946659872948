import { Component, ElementRef, ViewChildren, QueryList, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NewsletterService } from '../newsletter/newsletter.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  @ViewChildren('reviews') panes: QueryList<ElementRef>;

  public reviews = new Array<Review>();
  public reviewContainerHeight = 150;
  public reviewCnt = -1;
  public email: string;

  constructor(private newsletterSvc: NewsletterService, private router: Router) {
    this.reviews.push({ text: `"Ashley is so professional and sweet! She is so great to work with! The cake and cupcakes were not only beautiful but very very yummy!"`, visible: true, class: 'right', height: 0, author: 'Kristine T', date: '11/07/2020' });
    this.reviews.push({
      text: `"We ordered two dozen lemon and pb/chocolate cupcakes, and they were amazing!! They were a huge hit at the small event I was holding. It was raining the morning I picked up my order, but her husband was gracious enough to run them out to my car. So nice! I highly recommend."`,
      visible: false,
      class: 'right',
      height: 0,
      author: 'Jessica S',
      date: '10/27/2020'
    });
    this.reviews.push({
      text: `"Ashley absolutely knocks it out of the park every. single. time! Her cakes have become a staple for our family birthday celebrations. She has outstanding customer service and is incredibly talented! Not to mention, her cakes are absolutely delicious! Thank you, Ashley!!"`,
      visible: false,
      class: 'right',
      height: 0,
      author: 'Rachel M',
      date: '09/12/2020'
    });
    this.reviews.push({
      text: `"Simply amazing! Cupcakes tasted and looked SO good. Very professional. I’ll be definitely ordering again"`,
      visible: false,
      class: 'right',
      height: 0,
      author: 'Jessica F',
      date: '08/25/2020'
    });
    this.reviews.push({
      text: `"Amazing! I ordered a custom cake and cookies and all I can say is perfect, perfect, perfect! From the looks to the taste!"`,
      visible: false,
      class: 'right',
      height: 0,
      author: 'Karina M',
      date: '08/23/2020'
    });
    this.reviews.push({
      text: `"Amazing just simply amazing. From design to taste. I love love love CONFECTIONED. And this all comes from a person who prefers salty over sweet. I’d eat her treats before any chips, any day!"`,
      visible: false,
      class: 'right',
      height: 0,
      author: 'Shaniqua T',
      date: '06/25/2020'
    });

    this.reviews.push({
      text: `"Bought a cookie kit to decorate with my kids and they LOVED it! I was afraid at first there wasn’t enough frosting for all the cookies so I skimped on some at first but there was PLENTY and then some. They were fun to decorate but most importantly they tasted great!"`,
      visible: false,
      class: 'right',
      height: 0,
      author: 'Heidi C',
      date: '05/05/2020'
    });

    this.reviews.push({ text: `"We got the cookie kits for Easter and they were AMAZING! It was so fun to decorate and the cookies are absolutely delicious! Highly recommend for all your sugar cookie needs"`, visible: false, class: 'right', height: 0, author: 'Caela G', date: '04/11/2020' });
    this.reviews.push({ text: `"We ordered a set of unicorn/dinosaur cookies to decorate during isolation. We have four kids and they loved decorating them! Plus, the cookies were delicious!! We will always recommend CONFECTIONED!"`, visible: false, class: 'right', height: 0, author: 'Rachel', date: '04/18/2020' });

    setTimeout(() => this.go(), 1000);
    setInterval(() => this.go(), 10000);
  }
  ngOnInit(): void {
    window.scrollTo(0, 0);

    setTimeout(() => {
      document.getElementById('moveup').classList.add('no-margin-top');
    }, 0);
  }

  go() {
    this.reviewCnt++;
    if (this.reviewCnt >= this.reviews.length) {
      this.reviewCnt = 0;
    }
    if (this.reviews[this.reviewCnt]) {
      this.reviews[this.reviewCnt].visible = true;
      this.reviews[this.reviewCnt].height = ((this.panes.toArray()[this.reviewCnt].nativeElement as HTMLElement)
        .getBoundingClientRect().height + 14);
    }
    this.reviews.forEach((r) => {
      if (r.visible === true) {
        if (r.class === 'right') {
          r.class = 'center';
          this.reviewContainerHeight = r.height;
        } else if (r.class === 'center') {
          r.class = 'left';
        } else if (r.class === 'left') {
          r.class = 'right';
          r.visible = false;
        }
      }
    });
  }

  public gotoNewsletter(): void {
    this.newsletterSvc.setEmail(this.email);
    this.router.navigateByUrl('newsletter');
  }
}

class Review {
  text: string;
  author: string;
  date: string;
  class: string;
  visible: boolean;
  height: number;
}
