import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu/menu.component';
import { FlavorsComponent } from './flavors/flavors.component';
import { WindowRef } from './shared/services/window-ref.service';
import { ContactComponent } from './contact/contact.component';
import { NewsletterComponent } from './newsletter/newsletter.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        MenuComponent,
        FlavorsComponent,
        ContactComponent,
        NewsletterComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        RouterModule.forRoot([
            { path: '', component: HomeComponent, pathMatch: 'full' },
            { path: 'flavors', component: FlavorsComponent, pathMatch: 'full' },
            {
                path: 'contact-us',
                component: ContactComponent,
                pathMatch: 'full'
            },
            {
                path: 'newsletter',
                component: NewsletterComponent,
                pathMatch: 'full'
            },
            { path: '**', redirectTo: '/' }
        ])
    ],
    providers: [WindowRef],
    bootstrap: [AppComponent]
})
export class AppModule {}
