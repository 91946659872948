import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
private email: string;
  constructor() { }

  public setEmail(e: string): void {
    this.email = e;
  }

  public getEmail(): string {
    return this.email;
  }

}
