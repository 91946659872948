import { Component, HostListener, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"],
})
export class MenuComponent implements OnInit {
  public menuOpen = false;
  public headerHeight = "0px";
  @Input() showHeader = false;
  @Input() showHeaderScrollLimit = 0;

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (this.showHeader === true) {
      this.headerHeight = "62px";
    }
  }

  public toggleMenu() {
    this.menuOpen = !this.menuOpen;
    if (this.menuOpen === true) {
      this.headerHeight = "100vh";
    } else {
      if (this.showHeader === true) {
        this.headerHeight = "62px";
      } else {
        this.headerHeight = "0px";
      }
    }
  }

  public navigate(route: string): void {
    this.toggleMenu();
    setTimeout(() => {
      this.router.navigateByUrl(route);
    }, 500);
  }
  @HostListener("window:scroll", ["$event"]) onWindowScroll(e) {
    if (this.showHeaderScrollLimit > 0) {
      this.showHeader =
        e.target["scrollingElement"].scrollTop > this.showHeaderScrollLimit;

      if (this.menuOpen === true) {
        return;
      }

      if (this.showHeader === true) {
        this.headerHeight = "62px";
      } else {
        this.headerHeight = "0px";
      }
    }
  }
}
