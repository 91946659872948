import { Component, OnInit } from '@angular/core';
import { Contact } from '../shared/models/contact.model';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
    contact = new Contact();
    msgSubmitted = false;
    sendingMsg = false;
    data: any = null;
    error = '';

    constructor(private _http: HttpClient) { }

    ngOnInit() {
        window.scrollTo(0, 0);
    }

    public SendMessage() {
        if (
            !this.contact.firstName ||
            this.contact.firstName.trim().length < 3
        ) {
            this.error = 'Please enter a valid name';
        } else if (
            !this.contact.email ||
            this.contact.email.trim().length < 3 ||
            this.contact.email.includes('@') === false ||
            this.contact.email.includes('.') === false
        ) {
            this.error = 'Please enter a valid email address';
        } else if (
            !this.contact.subject ||
            this.contact.subject.trim().length < 1
        ) {
            this.error = 'Please enter a subject';
        } else if (
            !this.contact.message ||
            this.contact.message.trim().length < 1
        ) {
            this.error = 'Please enter a message';
        } else {
            this.sendingMsg = true;
            let base = location.protocol + '//' + location.hostname;
            if (base.includes('localhost')) {
                base += ':8080';
            }
            this._http
                .post(base + '/api/contact/', this.contact)
                .subscribe((data) => {
                    this.sendingMsg = false;
                    this.msgSubmitted = true;
                });
        }
    }

    public NotARobot() {
        this.contact.notARobot = !this.contact.notARobot;
    }

    public IsFormComplete() {
        return this.contact.IsFilledOut();
    }
}
