import { Component, OnInit } from '@angular/core';
import { Contact } from '../shared/models/contact.model';
import { HttpClient } from '@angular/common/http';
import { NewsletterService } from './newsletter.service';

@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {
    public firstName = '';
    public lastName = '';
    public email = '';
    public confirmedEmail = '';
    public error = '';
    public msgSubmitted = false;
    public sendingMsg = false;
    public data: any = null;
    private contact = new Contact();

    constructor(
        private _http: HttpClient,
        private newsletterSvc: NewsletterService
    ) { }

    ngOnInit() {
        this.email = this.newsletterSvc.getEmail();
        window.scrollTo(0, 0);
    }

    public SendMessage() {
        if (!this.firstName || this.firstName.trim().length < 3) {
            this.error = 'Please enter a valid first name';
        } else if (!this.lastName || this.lastName.trim().length < 3) {
            this.error = 'Please enter a valid last name';
        } else if (!this.email || this.email.trim().length < 3) {
            this.error = 'Please enter a valid email address';
        } else if (!this.confirmedEmail || this.email !== this.confirmedEmail) {
            this.error = 'Both email address fields must match';
        } else {
            let base = location.protocol + '//' + location.hostname;
            if (base.includes('localhost')) {
                base += ':8080';
            }
            this.sendingMsg = true;
            this.contact.firstName = this.firstName.trim();
            this.contact.lastName = this.lastName.trim();
            this.contact.email = this.email.trim();
            this._http
                .post(base + '/api/contact/subscribe/', this.contact)
                .subscribe((data) => {
                    this.sendingMsg = false;
                    this.msgSubmitted = true;
                });
        }
    }

    public IsFormComplete() {
        return this.contact.IsFilledOut();
    }

    public change() {
        this.error = '';
    }
}
