export class Contact {
    firstName = '';
    lastName = '';
    email = '';
    subject = '';
    message = '';
    notARobot = false;

    /**
     * IsFilledOut
     */
    public IsFilledOut(): boolean {
        return (
            this.firstName.trim() !== '' &&
            this.email.trim() !== '' &&
            this.subject.trim() !== '' &&
            this.message.trim() !== ''
        );
    }
}
